<template>
  <div class="main-content">
    <b-card>
      <good-data-table 
        ref="payrolls" 
        :columns="columns" :no-serial="true"
        :total-column="'response.responseData.total'" 
        :data-column="'response.responseData.data'"
        :api-endpoint="'/hrm/payroll'"
        :buttonLabel="'Add'"
        @add-new-info="Function_New_Payroll" 
      />

      <validation-observer ref="Create_Payroll">
        <b-modal v-model="payrollModal" hide-footer size="md" no-close-on-backdrop no-close-on-esc :title="editmode?$t('Edit'):$t('Add')">
          <b-form @submit.prevent="Submit_Payroll">
            <b-row>
              
                <!-- date -->
                <b-col md="12">
                  <validation-provider
                    name="date"
                    :rules="{ required: true}"
                    v-slot="validationContext"
                  >
                      <b-form-group :label="$t('Date') + ' ' + '*'">
                          <Datepicker id="date" name="date" :placeholder="$t('date')" v-model="payroll.date" 
                              input-class="form-control back_important" format="yyyy-MM-dd"  @closed="payroll.date=formatDate(payroll.date)">
                          </Datepicker>
                          <b-form-invalid-feedback id="date-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Employee -->
                <b-col md="12">
                  <validation-provider name="Employee" :rules="{ required: true}">
                    <b-form-group slot-scope="{ valid, errors }" :label="$t('Employee') + ' ' + '*'">
                      <v-select
                        :class="{'is-invalid': !!errors.length}"
                        :state="errors[0] ? false : (valid ? true : null)"
                        v-model="payroll.employee_id"
                        class="required"
                        required
                        @input="Selected_Employee"
                        :placeholder="$t('Choose Employee')"
                        :reduce="label => label.value"
                        :options="employees.map(employees => ({label: employees.username, value: employees.id}))"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Account -->
                <b-col md="12">
                  <validation-provider name="Account">
                    <b-form-group slot-scope="{ valid, errors }" :label="$t('Account')">
                      <v-select
                        :class="{'is-invalid': !!errors.length}"
                        :state="errors[0] ? false : (valid ? true : null)"
                        v-model="payroll.account_id"
                        @input="Selected_Account"
                        :reduce="label => label.value"
                        :placeholder="$t('Choose Account')"
                        :options="accounts.map(accounts => ({label: accounts.account_name, value: accounts.id}))"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Paying Amount  -->
                <b-col md="12">
                  <validation-provider
                    name="Amount"
                    :rules="{ required: true , regex: /^\d*\.?\d*$/}"
                    v-slot="validationContext"
                  >
                    <b-form-group :label="$t('Amount') + ' ' + '*'">
                      <b-form-input
                        @keyup="Verified_paidAmount"
                        label="Amount"
                        type="number"
                        :placeholder="$t('Paying Amount')"
                        v-model.number="payroll.amount"
                        :state="getValidationState(validationContext)"
                        aria-describedby="Amount-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="Amount-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Payment choice -->
                <b-col md="12">
                <validation-provider name="Payment Mode" :rules="{ required: true}">
                  <b-form-group slot-scope="{ valid, errors }" :label="$t('Payment Mode') + ' ' + '*'">
                    <v-select
                      :class="{'is-invalid': !!errors.length}"
                      :state="errors[0] ? false : (valid ? true : null)"
                      v-model="payroll.payment_method"
                      :reduce="label => label.value"
                      :placeholder="$t('Please Select')"
                      :options="
                            [
                            {label: 'Cash', value: 'Cash'},
                            {label: 'Credit Card', value: 'credit card'},
                            {label: 'TPE', value: 'tpe'},
                            {label: 'Cheque', value: 'cheque'},
                            {label: 'Western Union', value: 'Western Union'},
                            {label: 'Bank Transfer', value: 'Bank Transfer'},
                            {label: 'Other', value: 'other'},
                            ]"
                    ></v-select>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>


              <b-col md="12" class="mt-3">
                  <b-button variant="primary" type="submit"  :disabled="SubmitProcessing"><i class="i-Yes me-2 font-weight-bold"></i> {{$t('submit')}}</b-button>
                    <div v-once class="typo__p" v-if="SubmitProcessing">
                      <div class="spinner sm spinner-primary mt-3"></div>
                    </div>
              </b-col>

            </b-row>
          </b-form>
        </b-modal>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import Vue from 'vue'
import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
const apiUrl = `${jwtDefaultConfig.apiEndpoint}/hrm`;
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { mapActions, mapGetters } from 'vuex';

export default {
   components: {
    Datepicker, GoodDataTable
  },
  data() {
    return {
      isLoading: true,
      SubmitProcessing:false,
      payrollModal: false,
      editmode: false,
      payroll: {
        date: new Date().toISOString().slice(0, 10),
        employee_id:"",
        account_id:"",
        amount:"",
        payment_method:"",
      }, 
    };
  },

  computed: {
    ...mapGetters("account", ["accounts", "employees"]),
    columns() {
      return [
        {
          label: this.$t("Date"),
          field: "date",
          sortable: false
        },
        {
          label: this.$t("Reference"),
          field: "Ref",
          sortable: false
        },
        {
          label: this.$t("Employee"),
          field: "employee_name",
          sortable: false
        },
        {
          label: this.$t("Account"),
          field: "account_name",
          sortable: false
        },
        {
          label: this.$t("Amount"),
          field: "amount",
          sortable: false
        },
        {
          label: this.$t("Payment Mode"),
          field: "payment_method",
          sortable: false
        },
        {
          label: this.$t("Payment Status"),
          field: "payment_status",
          html: true,
          sortable: false
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.Function_Edit_Payroll(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('delete'),
              text: 'Delete',
              action: props => {
                this.$swal({
                  title: this.$t('You want to delete?'),
                  iconHtml: this.$helpers.swalIcon('alert.png'),
                  showCancelButton: true,
                  confirmButtonText: this.$t('Yes'),
                  cancelButtonText: this.$t('No'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                    icon: 'border-0',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.value) {
                    this.Remove_Payroll(props)
                  }
                })
              },
            },
          ],
        },
      ];
    }
  },

  methods: {

    ...mapActions('account', ['getAccountBasic']),
    //---------- keyup Received Amount

    Verified_paidAmount() {
      if (isNaN(this.payroll.amount)) {
        this.payroll.amount = 0;
      } 
    },


    //---- Validation State Form
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    formatDate(d){
        var m1 = d.getMonth()+1;
        var m2 = m1 < 10 ? '0' + m1 : m1;
        var d1 = d.getDate();
        var d2 = d1 < 10 ? '0' + d1 : d1;
        return [d.getFullYear(), m2, d2].join('-');
    },
  

    //------------- Submit Validation
    Submit_Payroll() {
      this.$refs.Create_Payroll.validate().then(success => {
        if (!success) {
          showDangerNotification(this, "Please fill the form correctly")
        } else {
          if (!this.editmode) {
            this.Create_Payroll();
          } else {
            this.Update_Payroll();
          }
        }
      });
    },

    //------ Toast
    makeToast(variant, msg, title) {
      this.$root.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true
      });
    },

   //------------------------------ Show Modal (Create Payroll) -------------------------------\\
    Function_New_Payroll() {
        this.reset_Form();
        this.editmode = false;
        this.payrollModal = true;
    },

    //------------------------------ Show Modal (Update Payroll) -------------------------------\\
    Function_Edit_Payroll(payroll) {
        this.editmode = true;
        this.reset_Form();
        this.payroll = payroll;
        this.payrollModal = true;
    },

   
    Selected_Account(value) {
        if (value === null) {
            this.payroll.account_id = "";
        }
    },

    Selected_Employee(value) {
        if (value === null) {
            this.payroll.employee_id = "";
        }
    },


    //------------------------------- Create payroll ------------------------\\
    Create_Payroll() {
      
        var self = this;
        self.SubmitProcessing = true;
        axios.post(apiUrl+"/payroll", {
          date: self.payroll.date,
          employee_id: self.payroll.employee_id,
          account_id: self.payroll.account_id,
          amount: self.payroll.amount,
          payment_method: self.payroll.payment_method,
        }).then(response => {
            this.SubmitProcessing = false;
            this.$refs.payrolls.loadItems();
            this.payrollModal = false;
            showSuccessNotification(this, "Created successfully");
        })
        .catch(error => {
          this.SubmitProcessing = false;
          showErrorNotification(this, "Something went wrong while creating the payrol");
        });
    },

    //------------------------------- Update payroll ------------------------\\
    Update_Payroll() {

      var self = this;
      self.SubmitProcessing = true;
      axios.put(apiUrl+"/payroll/" + self.payroll.id, {
          date: self.payroll.date,
          employee_id: self.payroll.employee_id,
          account_id: self.payroll.account_id,
          amount: self.payroll.amount,
          payment_method: self.payroll.payment_method,
      }).then(response => {
            this.SubmitProcessing = false;
            this.$refs.payrolls.loadItems();
            this.payrollModal = true;
            showSuccessNotification(this, "Updated successfully");
        })
        .catch(error => {
          this.SubmitProcessing = false;
          showErrorNotification(this, "Failed to update");
        });
    },

    //------------------------------- reset Form ------------------------\\
    reset_Form() {
     this.payroll = {
        id: "",
        date: new Date().toISOString().slice(0, 10),
        employee_id:"",
        account_id:"",
        amount:"",
        payment_method:"",
    };
    },

    //------------------------------- Delete payroll ------------------------\\
    Remove_Payroll(props) {
      axios
        .delete(apiUrl+"/payroll/" + props.id)
        .then(() => {
          this.$refs.payrolls.loadItems();
          showSuccessNotification(this, "Deleted successfully");
        })
        .catch(() => {
          showErrorNotification(this, "Failed to delete");
        });
    },

  
  },

  //----------------------------- Created function-------------------\\

  beforeMount: function() {
    this.getAccountBasic({
      only: ["accounts", "employees"]
    });
  },
  mounted: function() {
    this.$refs.payrolls.loadItems();
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
